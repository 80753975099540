<template>
  <div class="g-section">
    <div class="g-wrap flex-column">
      <content-header :title-list="titleList"></content-header>
      <div class="from-content">
        <!-- <div class="flex u-row-between u-col-center top">
          <el-radio-group class="switch" v-model="queryParams.Status" size="medium" @change="handleChange">
            <el-radio-button label="">
              <span class="el-icon-s-comment"></span>
              <span>收到简历</span>
            </el-radio-button>
            <el-radio-button label="1">
              <span class="el-icon-s-custom"></span>
              <span>候选人</span>
            </el-radio-button>
          </el-radio-group>
          <div class="flex">
            <el-select v-if="false" v-model="queryParams.OwnerType" placeholder="请选择">
              <el-option v-for="item in ownerTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-select v-if="true" v-model="queryParams.RecruitJobId" placeholder="请选择岗位" @change="jobChange">
              <el-option v-for="item in jobOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div> -->
        <div class="bottom">
          <list text="暂无信息" :dataList="list" :totalCount="totalCount" :loading="loading" @loadMore="load">
            <div class="item flex p-16" v-for="(item, index) in list" :key="index">
              <div class="left">
                <div class="headline mb-8">
                  <p class="text-bold fs-16 color-base">姓名:{{item.RealName}}</p>
                  <p class="pt-16">手机：{{ item.Phone }}</p>
                  <p class="pt-16">邮箱：{{ item.Email }}</p>
                </div>
                <!-- <div class="subline mb-8">
                  <span class="pr-16 ">{{ item.HighestEducation }}</span>
                  <span>{{ item.Major }}</span>
                </div> -->
                <div class="subline pt-16">
                  <span>备注：</span>
                  <span class="color-light">{{ item.Remarks }}</span>
                </div>
              </div>
              <div class="right">
                <div class="ml-30">
                  <!-- <el-button type="text" icon="el-icon-view" size="small" @click="$router.push(`/enterprise/recruitnews/applylist?id=${item.Id}`)">查看
                  </el-button> -->
                  <el-button type="text" size="small" icon="el-icon-s-check" v-if="item.AuditStatus == 0" @click="audit(item)">审核
                  </el-button>
                  <div class="fs-14 color-primary" v-if="item.AuditStatus == 2">
                    审核通过
                  </div>
                  <div class="fs-14 color-danger" v-if="item.AuditStatus == 3">
                    审核未通过
                  </div>
                </div>
              </div>
            </div>
          </list>
        </div>
      </div>
    </div>
    <el-dialog title="审核报名信息" :visible.sync="dialogVisible" width="460px">
      <el-form label-width="90px">
        <el-form-item label="审核意见：">
          <el-radio-group v-model="radio">
            <el-radio-button :label="2">通过</el-radio-button>
            <el-radio-button :label="3">不通过</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input v-model="remarks"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAudit">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getPageList, auditInfo } from "@/api/recruitapply";
import ContentHeader from "@/components/Common/ContentHeader";
import List from "@/components/Common/List";
export default {
  components: {
    ContentHeader,
    List
  },
  data() {
    return {
      titleList: [
        {
          text: "招生动态",
          isCur: false,
          link: ""
        },
        {
          text: "报名",
          isCur: true,
          link: ""
        }
      ],
      queryParams: {
        Status: 0,
        KeyWord: "",
        RecruitNewsId: "",
        PageSize: 10,
        PageIndex: 0
      },
      ownerTypeOptions: [
        {
          value: null,
          label: "全部状态"
        }
      ],
      list: [],
      totalCount: 0,
      loading: false,
      dialogVisible: false,
      radio: 2,
      remarks: "",
      objItem: ""
    };
  },
  computed: {
    ...mapGetters(["employee"])
  },
  created() {
    this.queryParams.RecruitNewsId = this.$route.query.id;
    this.load();
  },
  methods: {
    handleChange(val) {
      this.queryParams.Status = val;
      this.queryParams.PageIndex = 0;
      this.list = [];
      this.totalCount = 0;
      this.load();
    },
    load() {
      this.loading = true;
      this.queryParams.PageIndex++;
      getPageList(this.queryParams).then(({ Result: { Data, TotalCount } }) => {
        this.list = this.list.concat(Data);
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    search() {
      this.queryParams.PageIndex = 0;
      this.list = [];
      this.totalCount = 0;
      this.load();
    },
    jobChange() {
      this.queryParams.PageIndex = 0;
      this.list = [];
      this.totalCount = 0;
      this.load();
    },
    // 审核
    audit(item) {
      this.objItem = item;
      this.dialogVisible = true;
    },
    // 保存审核意见
    saveAudit() {
      var params = {
        Id: this.objItem.Id,
        AuditStatus: this.radio,
        AuditOpinion: this.remarks
      };
      auditInfo(params).then(res => {
        console.log(res);
        this.load();
      });
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.g-section {
  background: #f5f5f5;
}
.switch {
  ::v-deep .el-radio-button__inner {
    line-height: 18px;
    font-size: 14px;
    // background: red;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: rgba(1, 188, 119, 0.08);
    color: #598DF3;
  }
}
.keyword {
  ::v-deep .el-input__inner {
    height: 40px;
    line-height: 40px;
  }
}
.from-content {
  background: #fff;
  // margin: 8px 0;
  border-radius: 8px;
}
.top {
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid #eee;
}
.bottom {
  padding: 16px 24px;
  .item {
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    &:hover {
      box-shadow: 0px 4px 12px 0px rgba(51, 51, 51, 0.16),
        0px -1px 0px 0px #eeeeee;
    }
    .left {
      display: flex;
      flex-direction: column;
      flex: 2;
      color: #666;
    }
    .right {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
