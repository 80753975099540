<template>
  <div :class="['data-list', className]" v-if="dataList && dataList.length">
    <slot></slot>
    <div class="loading">
      <el-button type="text" v-if="loading">加载中...</el-button>
      <el-button type="text" v-else-if="noMore" disabled class="nomore-text">
        —— 没有更多了 ——
      </el-button>
      <el-button v-else @click="load" type="text">加载更多</el-button>
    </div>
  </div>
  <div v-else :class="['nodata', className2]">
    <el-image
      style="width: 240px; height: 180px"
      :src="require('@/assets/images/nodata.png')"
    >
    </el-image>
    <span v-if="text" class="mt-18">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String
    },
    className2: {
      type: String
    },
    text: {
      type: String
    },
    dataList: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    noMore() {
      return this.dataList.length == this.totalCount;
    }
  },
  methods: {
    load() {
      this.$emit("loadMore");
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nodata {
  padding: 40px;
  flex: 1;
  flex-direction: column;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
  }
}
</style>
